import { InlineStack } from '@shopify/polaris';
import SaveToast from 'barn/components/Toast/Save';
import CloseIcon from 'barn/icons/Close';
import tokens from 'barn/tokens';
import { useClientside } from 'src/hooks/use-clientside';
import { useGlobalState } from 'src/hooks/use-global-state';
import { CloseButton } from './styled';

export const Toast = () => {
  const { state: toastState, actions } = useGlobalState('saveToast');
  const isClientSide = useClientside();

  if (isClientSide) {
    return (
      <SaveToast
        isLoading={toastState.isLoading}
        hidden={toastState.hidden}
        type={toastState.type}
      >
        <InlineStack blockAlign='center' gap='600' align='space-between'>
          {toastState.message}
          <CloseButton aria-label='close button' onClick={actions.hide}>
            <CloseIcon color={tokens.colors.greys[8]} size='18px' />
          </CloseButton>
        </InlineStack>
      </SaveToast>
    );
  }
  return null;
};
