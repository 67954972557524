import { isBefore } from 'date-fns';
import { CampaignType } from '../models';

export const isValidSchedule = ({
  segment,
  campaignType,
  sendType,
  scheduledTime,
  endCampaignTime,
}) => {
  if (!segment) {
    return false;
  }

  if (campaignType === CampaignType.FlashSale && !endCampaignTime) {
    return false;
  }

  if (sendType === 'scheduled' && !scheduledTime) {
    return false;
  }

  return true;
};

export const validateCampaignSchedule = ({
  campaignType,
  sendType,
  scheduledTime,
  endCampaignTime,
}) => {
  // ttl for campaign run
  let ttl = 2419200; /* seconds in 28 days */
  if (sendType === 'scheduled' && endCampaignTime) {
    ttl =
      (new Date(endCampaignTime).getTime() -
        new Date(scheduledTime).getTime()) /
      1000;
  }
  if (sendType === 'now' && endCampaignTime) {
    ttl = (new Date(endCampaignTime).getTime() - new Date().getTime()) / 1000;
  }

  // use 30 seconds as minimum ttl
  let isValid = true;
  let error = null;
  if (ttl < 30) {
    isValid = false;
    error = 'Campaign schedule time is in the past';

    if (
      campaignType === CampaignType.FlashSale &&
      isBefore(
        new Date(endCampaignTime),
        sendType === 'scheduled' ? new Date(scheduledTime) : new Date(),
      )
    ) {
      error =
        sendType === 'scheduled'
          ? "Flash Sale's expiry time is before its scheduled start time."
          : "Flash Sale's expiry time has already passed.";
    }
  }

  return {
    isValid,
    error,
  };
};

export default {}; // eslint-hack
