/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export const useFormValidation = ({
  setErrors,
  state,
  validationSchema,
  runOn = ['blur'],
  debounceInterval = 100,
}) => {
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  async function validate() {
    try {
      await validationSchema.validate(stateRef.current, {
        abortEarly: false,
      });
      return {};
    } catch (error) {
      const errors = {};
      if (error.inner.length) {
        error.inner.forEach(e => {
          errors[e.path] = errors[e.path] || e.message;
        });
      } else {
        errors[error.path] = error.message;
      }

      return errors;
    }
  }

  const validateField = async fieldName => {
    const errs = await validate();

    setErrors({
      errors: {
        [fieldName]: errs[fieldName],
      },
    });
  };

  const debouncedValidateField = useMemo(
    () => debounce(validateField, debounceInterval),
    [],
  );

  const retVal = {
    validate: async () => {
      const errs = await validate();
      setErrors({ errors: errs, merge: false });
      return !Object.keys(errs).length;
    },
    formProps: { onBlur: (e: any) => {}, onChange: (e: any) => {} },
  };

  retVal.formProps.onBlur = e => {
    const fieldName = e.target.name;
    debouncedValidateField(fieldName);
  };

  retVal.formProps.onChange = async e => {
    const fieldName = e.target.name;
    setErrors({
      errors: {
        [fieldName]: null,
      },
    });
  };

  return retVal;
};
