import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  margin: 0 0 ${tokens.space.margin[2]};
  font-size: ${fontSizes[2]};
  color: ${tokens.colors.greys[0]};
  font-weight: 500;
`;

const StyledInput = styled.input<{ highlightColor?: string }>`
  box-sizing: border-box;
  border-radius: ${tokens.radii[4]};
  outline: transparent;
  border: 1px solid ${tokens.colors.greys[5]};
  background: ${tokens.colors.greys[8]};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  height: 36px;
  font-size: ${fontSizes[2]};
  color: ${tokens.colors.greys[0]};
  max-width: 100ch;

  &:hover,
  &:focus {
    border-color: ${({ highlightColor }) =>
      highlightColor || tokens.colors.blues[5]};
  }

  &:focus {
    /*  
       Using box-shadow instead of outline 
       since outline won't have border-radius in Safari and old Edge (v18)
      */
    box-shadow: inset 0 0 0 1px
      ${({ highlightColor }) => highlightColor || tokens.colors.blues[5]};
  }

  &[disabled],
  &:disabled {
    color: ${tokens.colors.greys[3]};
    cursor: not-allowed;

    &:hover {
      border-color: ${tokens.colors.greys[5]};
    }
  }

  &.error {
    background-color: ${tokens.colors.reds[9]};
    border-color: ${tokens.colors.reds[5]};
  }

  &.error:focus {
    box-shadow: inset 0 0 0 1px ${tokens.colors.reds[5]};
  }

  &.inactive {
    background: ${tokens.colors.greys[6]};
    border-color: ${tokens.colors.greys[6]};
    opacity: 1;
    color: ${tokens.colors.greys[3]};
  }

  &.inactive:focus {
    box-shadow: none;
  }
`;

export const StyledAsterisk = styled.span`
  margin: 0 0 0 ${tokens.space.margin[1]};
  color: ${tokens.colors.reds[5]};
  font-size: ${fontSizes[4]};
`;

export const StyledError = styled.div`
  margin-top: ${tokens.space.margin[2]};
  font-size: ${fontSizes[1]};
  color: ${tokens.colors.reds[3]};
`;

export default StyledInput;

export const StyledColorPickerWidget = styled.button<{
  value: string;
}>`
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid ${tokens.colors.greys[5]};
  border-radius: ${tokens.radii[3]};
  cursor: pointer;
  background-color: ${props => props.value};
  right: 10px;
`;

export const StyledColorPicker = styled.div`
  position: absolute;
  left: 10px;
  top: -100px;

  @media (max-width: ${responsiveScreenBreakpoint}) {
    left: auto;
    right: 40px;
  }

  z-index: ${tokens.zIndex[5]};

  * {
    font-family: inherit !important;
  }

  *:not(input) {
    cursor: pointer;
  }
`;
