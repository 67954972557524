import RegexValidations from 'src/lib/regex-validations';
import * as yup from 'yup';

export const WARN_MESSAGE_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 500;

const EMPTY_TITLE_MESSAGE = 'Empty title: Please enter a Title';
const EMPTY_LINK_MESSAGE = 'Empty link: Please enter a URL';

const title = yup.string().trim().required(EMPTY_TITLE_MESSAGE);
const primaryLink = yup
  .string()
  .required(EMPTY_LINK_MESSAGE)
  .test('urlFormat', 'Enter valid primary link', RegexValidations.url);

const discountCode = yup
  .string()
  .notRequired()
  .nullable()
  // discount code is not mandatory but when it is present then the following piece of code will run
  .when('discountCode', {
    is: (value: string) => value?.length,
    then: yup
      .string()
      .ensure()
      .test(
        'Discount code should not be empty',
        'Discount code can not be blank spaces',
        value => value.trim().length > 0,
      )
      .test(
        'Discount code should not be a url',
        'Enter only the discount code here. Add discount code link in the "Primary Link" field',
        value => !RegexValidations.url(value),
      ),
  });

// yup schema for an array of buttons where each button should have a title
const buttons = yup.array().of(
  yup.object().shape({
    title,
    link: yup
      .string()
      .required(EMPTY_LINK_MESSAGE)
      .test('urlFormat', 'Enter a valid URL', RegexValidations.url),
  }),
);

const automationButtons = yup.array().of(
  yup.object().shape({
    title,
    link: yup
      .string()
      .notRequired()
      .test('urlFormat', 'Enter a valid URL', value => {
        return !value || RegexValidations.url(value);
      }),
  }),
);

export const validationSchema = yup.object().shape(
  {
    title,
    primaryLink,
    discountCode,
    buttons,
  },
  [['discountCode', 'discountCode']],
);

export const welcomeAutomationValidationSchema = yup.object().shape({
  title,
  primaryLink,
  buttons: automationButtons,
});

export const automationValidationSchema = yup.object().shape({
  title,
  buttons: automationButtons,
});

const shippingAutomationButtons = yup.array().of(
  yup.object().shape({
    title,
    link: yup
      .string()
      .nullable()
      .test('urlFormat', 'Enter a valid URL', (value, { path }) => {
        // Running URL validation only for the second button
        if (path === 'buttons[1].link') {
          return RegexValidations.url(value);
        }

        return true;
      }),
  }),
);

export const shippingAutomationValidationSchema = yup.object().shape({
  title,
  buttons: shippingAutomationButtons,
});
