import tokens from 'barn/tokens';
import styled from 'styled-components';

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: transparent;
  background: transparent;
  padding: ${tokens.space.padding[2]};
  cursor: pointer;
  border-top-right-radius: ${tokens.radii[5]};
`;
