import React from 'react';
import TickIcon from '../../icons/Tick';
import { StyledTickCircleHolder, StyledTickCircle } from './styled';

interface TickCircleProps {
  active?: boolean;
  done?: boolean;
  label?: string;
}
const TickCircle: React.FC<TickCircleProps> = ({
  active = false,
  done = false,
  label = null,
}) => {
  let klass = '';

  if (active) klass += 'active';
  if (done) klass += 'complete';
  if (label) klass += '  withLabel';

  return (
    <StyledTickCircleHolder>
      <StyledTickCircle className={klass}>
        {done && <TickIcon />}
      </StyledTickCircle>
      {label && <p className='label'>{label}</p>}
    </StyledTickCircleHolder>
  );
};

export default TickCircle;
