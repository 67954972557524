import dynamic from 'next/dynamic';
import { Spinner } from '@shopify/polaris';
import { FC, useState, useEffect, useMemo, Suspense, Fragment } from 'react';
import tokens from 'barn/tokens';
import { StyledColorPicker, StyledColorPickerWidget } from './styled';

interface Props {
  value: string;
  onChange: (string) => void;
  inputRef: AnyObject;
}

const decimalToHex = (alpha: number) =>
  alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

const handleColorChange = ({ hex, rgb }) =>
  `${hex}${decimalToHex(rgb.a)}`.toUpperCase();

const ColorPicker: FC<Props> = ({ value, onChange, inputRef }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const ChromePicker: any = useMemo(() => {
    if (isPickerOpen) {
      return dynamic(() => import('react-color/lib/components/chrome/Chrome'));
    }

    return Fragment;
  }, [isPickerOpen]);

  useEffect(() => {
    const clickHandler = e => {
      if (e.target !== inputRef.current && !e.defaultPrevented)
        setIsPickerOpen(false);
    };

    const keyboardHandler = e => {
      if (e.key === 'Escape') {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener('keydown', keyboardHandler, false);
    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keydown', keyboardHandler, false);
    };
  }, []);

  return (
    <>
      <StyledColorPickerWidget
        {...{
          value,
        }}
        type='button'
        onClick={e => {
          setIsPickerOpen(!isPickerOpen);
          e.preventDefault();
        }}
      />

      {isPickerOpen && (
        <div
          style={{
            position: 'relative',
          }}
        >
          <StyledColorPicker onClick={e => e.preventDefault()}>
            <Suspense
              fallback={
                <div
                  style={{
                    padding: tokens.space.padding[3],
                    minHeight: '30px',
                    minWidth: '130px',
                  }}
                >
                  <Spinner size='small' />
                </div>
              }
            >
              <ChromePicker
                color={value}
                onChange={color => onChange(handleColorChange(color))}
              />
            </Suspense>
          </StyledColorPicker>
        </div>
      )}
    </>
  );
};

export default ColorPicker;
