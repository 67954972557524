import { Box, InlineStack } from '@shopify/polaris';
import InfoRoundedFilledIcon from 'barn/icons/InfoRoundedFilled';
import React, { useEffect, useRef } from 'react';
import tokens from 'barn/tokens';
import useTranslation from 'next-translate/useTranslation';
import ColorPicker from './ColorPicker';
import StyledInput, {
  StyledInputContainer,
  StyledLabel,
  StyledAsterisk,
  StyledError,
} from './styled';

export const SimpleInput = StyledInput;
export const Label = StyledLabel;

interface InputProps {
  onChange?: (value: string | number) => void;
  onBlur?: (value: string | number) => void;
  type?: string;
  value?: string | number;
  placeholder?: string;
  name: string;
  label?: string;
  hasError?: boolean;
  disabled?: boolean;
  style?: AnyObject;
  fullWidth?: boolean;
  isMandatory?: boolean;
  autoFocus?: boolean;
  inactive?: boolean;
  validate?: (value?: any) => boolean;
  tabIndex?: any;
  error?: string | undefined;
  colorPicker?: boolean;
  testHandle?: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  rightElement?: any;
}
const Input: React.FunctionComponent<InputProps> = ({
  name,
  onChange = null,
  onBlur = null,
  type = 'text',
  value = '',
  placeholder = '',
  label = null,
  autoFocus = false,
  hasError = false,
  disabled = false,
  style = {},
  fullWidth = false,
  isMandatory = false,
  inactive = false,
  readOnly = false,

  tabIndex = 0,
  error,
  colorPicker,
  testHandle,
  min,
  max,
  rightElement,
}) => {
  const { t } = useTranslation('common');

  const className = `${error ? 'error' : ''} ${
    inactive || readOnly ? 'inactive' : ''
  }`;
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPosition = useRef<number>();

  const handleChange: ReactEventHandler = event => {
    const newValue = event.target.value;

    // Save cursor position on user event
    cursorPosition.current = inputRef!.current!.selectionStart; // eslint-disable-line

    if (onChange) {
      onChange(type === 'number' ? parseInt(newValue, 10) : newValue);
    }
  };

  const handleBlur: ReactEventHandler = event => {
    const newValue = event.target.value;

    // Save cursor position on user event
    cursorPosition.current = inputRef!.current!.selectionStart; // eslint-disable-line

    if (onBlur) {
      onBlur(type === 'number' ? parseInt(newValue, 10) : newValue);
    }
  };

  useEffect(() => {
    if (hasError && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [hasError, inputRef]);

  // Async updates cause jumps in cursor. So we reset to the last saved cursor
  useEffect(() => {
    if (!cursorPosition.current) return;
    inputRef.current.setSelectionRange(
      cursorPosition.current,
      cursorPosition.current,
    );
  }, [value]);

  // set 100% width if `fullWidth` is supplied
  if (fullWidth) {
    // eslint-disable-next-line
    style.width = '100%';
  }

  if (colorPicker || rightElement) {
    // eslint-disable-next-line no-param-reassign, prefer-destructuring
    style.paddingRight = tokens.space.padding[9];
  }

  const input = (
    <StyledInput
      ref={inputRef}
      id={name}
      name={name}
      className={className}
      type={type}
      onChange={handleChange}
      onBlur={handleBlur}
      readOnly={readOnly}
      value={value}
      placeholder={placeholder}
      disabled={disabled || inactive}
      tabIndex={tabIndex}
      style={style}
      data-testid={testHandle}
      {...(type === 'number' ? { min, max } : {})}
      autoFocus={autoFocus}
    />
  );

  return (
    <StyledInputContainer>
      {label && (
        <StyledLabel htmlFor={name}>
          {t(label)}
          {isMandatory && <StyledAsterisk>*</StyledAsterisk>}
        </StyledLabel>
      )}
      {colorPicker || rightElement ? (
        <Box position='relative'>
          <InlineStack blockAlign='center'>
            {input}
            {rightElement || (
              <ColorPicker
                value={value as string}
                onChange={onChange}
                inputRef={inputRef}
              />
            )}
          </InlineStack>
        </Box>
      ) : (
        input
      )}
      {error && (
        <StyledError>
          <InlineStack gap='200' blockAlign='center'>
            <InfoRoundedFilledIcon size={16} />
            {error}
          </InlineStack>
        </StyledError>
      )}
    </StyledInputContainer>
  );
};

export default Input;
