import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';

export const StyledTickCircleHolder = styled.div`
  display: flex;
  align-items: center;

  .withLabel {
    margin-right: ${tokens.space.margin[3]};

    & + .label {
      font-size: ${fontSizes[3]};
      font-weight: normal;
      color: ${tokens.colors.greys[1]};

      @media screen and (max-width: ${responsiveScreenBreakpoint}) {
        font-size: ${fontSizes[2]};
      }
    }
  }
`;

export const StyledTickCircle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid ${tokens.colors.greys[5]};
  border-radius: 50%;
  background: ${tokens.colors.greys[5]};
  width: 28px;
  height: 28px;
  flex-shrink: 0;

  @media screen and (max-width: ${responsiveScreenBreakpoint}) {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }

  &.active {
    background: ${tokens.colors.greys[8]};
    border-color: ${tokens.colors.greens[4]};
  }

  &.complete {
    background: ${tokens.colors.greens[4]};
    border-color: ${tokens.colors.greens[4]};

    .iconColor {
      fill: ${tokens.colors.greys[8]};
    }
  }
`;
