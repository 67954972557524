import { Component } from 'react';
import { Text } from '@shopify/polaris';
import { logErrorToSentry, debugLog } from 'src/lib/debug-utils';
import { ignoredErrors } from './ignoredErrors';

type Props = {
  children: React.ReactNode;
};

export class ErrorBoundary extends Component<Props> {
  state = { hasError: false };

  componentDidMount(): void {
    window.addEventListener('error', event => {
      const hasError = !ignoredErrors.find(err => event.message.includes(err));

      if (hasError) {
        debugLog({
          message: 'window errors',
          data: {
            event,
          },
        });

        logErrorToSentry({ error: event.message });
      }
    });

    window.addEventListener('unhandledrejection', ({ reason }) => {
      const hasError = !ignoredErrors.find(
        err => reason?.message.includes(err),
      );

      if (hasError) {
        logErrorToSentry({ error: reason });
      }
    });
  }

  static getDerivedStateFromError(event) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: !ignoredErrors.find(err => event.message.includes(err)),
    };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToSentry({ error, extras: errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '3rem',
            display: 'grid',
            placeContent: 'center',
          }}
        >
          <Text as='h2' variant='headingXl'>
            Apologies! An error was encountered. Please try the following to
            troubleshoot:
          </Text>
          <ol>
            <li>
              <Text as='p' variant='bodyLg'>
                Refresh the page
              </Text>
            </li>
            <li>
              <Text as='p' variant='bodyLg'>
                Login again via your platform
              </Text>
            </li>
            <li>
              <Text as='p' variant='bodyLg'>
                Contact customer support
              </Text>
            </li>
          </ol>
        </div>
      );
    }

    return children;
  }
}
