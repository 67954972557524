import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';
import debounce from 'lodash.debounce';
import { StoreState, Dispatch } from 'src/store';
import { useRouter } from 'next/router';
import { isBefore } from 'date-fns';
import {
  CampaignCreatorState,
  CampaignType,
  FunctionMode,
  SendType,
} from 'src/modules/campaign-creator/models';
import useCreateNotification from 'src/hooks/use-create-notification';
import {
  MAX_MESSAGE_LENGTH,
  validationSchema,
} from 'src/modules/notification-creator/util/validations';
import { CampaignTypes } from 'src/components/ReportSaver/models';
import useAuth from './use-auth';
import { isValidSchedule } from '../modules/campaign-creator/util/validations';
import { useFormValidation } from './use-form-validation';

const useCreateCampaign = () => {
  const dispatch: Dispatch = useDispatch();
  const { user } = useAuth();
  const router = useRouter();
  const { state: createNotificationState, actions: createNotificationActions } =
    useCreateNotification();
  const { campaignCreator, notificationCreator } = dispatch;

  const { validate: validateNotificationCreator } = useFormValidation({
    validationSchema,
    state: createNotificationState,
    setErrors: createNotificationActions.setErrors,
  });

  const debouncedCreateCampaign = useMemo(
    () => debounce(campaignCreator.createCampaign, 1000),
    [],
  );

  const saveAsDraft = () => {
    if (
      ['/campaigns/create/1', '/campaigns/create/2'].includes(router.asPath) &&
      state.isDraft
    ) {
      debouncedCreateCampaign({ isDraft: true });
    }
  };

  const getPropSetter =
    (prop: string) =>
    (
      value: any,
      { isInitial }: { isInitial: boolean } = { isInitial: false },
    ) => {
      campaignCreator.setProp({ value, prop });

      if (!isInitial) {
        saveAsDraft();
      }
    };

  const state = useSelector(
    (storeState: StoreState) => storeState.campaignCreator,
  );

  const actions = {
    start: (value?: Partial<CampaignCreatorState>) => {
      campaignCreator.shut(); // shut if not already done
      campaignCreator.start(value);
    },
    saveShopifyMarketingAutomation:
      campaignCreator.saveShopifyMarketingAutomation,
    fetchShopifyMarketingAutomation:
      campaignCreator.fetchShopifyMarketingAutomation,
    shut: campaignCreator.shut,
    setCampaignType: getPropSetter('campaignType'),
    setSegment: getPropSetter('segment'),
    setSendType: getPropSetter('sendType'),
    setScheduledTime: getPropSetter('scheduledTime'),
    setEndCampaignTime: getPropSetter('endCampaignTime'),
    setSmartDelivery: getPropSetter('smartDelivery'),
    setError: getPropSetter('error'),
    createCampaign: campaignCreator.createCampaign,
    validateDiscountCode: campaignCreator.validateDiscountCode,
    duplicate: (campaign: AnyObject) => {
      const buttons = campaign.actions.map(action => ({
        title: action.title,
        link: action.redirect_url,
        id: uuidv1(),
      }));

      notificationCreator.loadState({
        buttons,
        title: campaign.title,
        message: campaign.description,
        primaryLink: campaign.redirect_url,
        discountCode: campaign.discount_code || null,
        // if user has hero image disabled, don't use image from campaign
        heroImage:
          user.website.website_plan &&
          !user.website.website_plan.enable_big_image
            ? {
                desktop: null,
                mobile: null,
              }
            : {
                desktop: campaign.image,
                mobile: campaign.mobile_image,
                macos: campaign.images.mac_image,
              },
        icon: campaign.icon,
      });

      campaignCreator.shut();
      campaignCreator.start();

      const now = new Date();

      const getScheduled = () => {
        // This is needed because there are some difference between campaign listing and draft API
        // We need to make both APIs same to remove this
        const scheduledTime = Number.isInteger(campaign.scheduled_time)
          ? new Date(campaign.scheduled_time * 1000)
          : new Date(campaign.scheduled_time);

        if (isBefore(now, scheduledTime)) {
          return [scheduledTime.toISOString(), SendType.SCHEDULED];
        }

        return [now.toISOString(), SendType.NOW];
      };

      const [scheduledTime, sendType] = getScheduled();
      const getEndCampaignTime = () => {
        const endCampaignTime = new Date(campaign.end_campaign_time);
        if (
          isBefore(now, endCampaignTime) &&
          isBefore(new Date(scheduledTime), endCampaignTime)
        ) {
          return endCampaignTime.toISOString();
        }

        return now.toISOString();
      };

      const campaignObject = {
        isDraft: true,
        sendType,
        scheduledTime,
        segment: {
          id: campaign.segment_id,
        },
        campaignType:
          // The campaign listing API sends campaign type as smart
          campaign.type === CampaignType.FlashSale
            ? CampaignType.FlashSale
            : CampaignType.Regular,
        endCampaignTime:
          campaign.type === CampaignType.FlashSale
            ? getEndCampaignTime()
            : null,
        templateId: campaign.template_id,
        smartDelivery: campaign.type === 'smart',
      };

      campaignCreator.loadState(campaignObject);

      router.push('/campaigns/create/1');
    },
    edit: (campaign: AnyObject) => {
      const buttons = campaign.actions.map(action => ({
        title: action.title,
        link: action.redirect_url,
        id: uuidv1(),
      }));

      const campaignCreatorState: any = {
        segment: {
          id: campaign.segment_id,
        },
        sendType: campaign.scheduled_time ? 'scheduled' : 'now',
        scheduledTime: campaign.scheduled_time
          ? new Date(campaign.dispatched_at)
          : null,
        smartDelivery: campaign.type === CampaignTypes.SMART,
        campaignType:
          campaign.type === CampaignTypes.FLASH
            ? CampaignType.FlashSale
            : CampaignType.Regular,
        mode: FunctionMode.Edit,
        isDraft: campaign.status === 'draft',
        id: campaign.id,
        templateId: campaign.template_id,
      };

      if (campaign.end_campaign_time) {
        campaignCreatorState.endCampaignTime = new Date(
          campaign.end_campaign_time * 1000,
        );
      }

      campaignCreator.shut();
      campaignCreator.loadState(campaignCreatorState);

      notificationCreator.loadState({
        buttons,
        title: campaign.title,
        message: campaign.description,
        primaryLink: campaign.redirect_url,
        discountCode: campaign.discount_code || null,
        heroImage: {
          desktop: campaign.image,
          mobile: campaign.mobile_image,
          macos: campaign.images.mac_image,
        },
        icon: campaign.icon,
      });
      campaignCreator.start();
      router.push('/campaigns/create/1');
    },
    handlePrevious: (newStep: number) => {
      router.replace(
        '/campaigns/create/[step]',
        `/campaigns/create/${newStep}`,
      );
    },
    handleNext: async (newStep: number) => {
      // TODO: These validations don't belong here since they use data from notification
      // creator. They should be moved to notification creator or all validations should be
      // moved here
      const { message } = createNotificationState;

      if (isValidSchedule(state)) {
        if (newStep === 3) {
          const isValid = await validateNotificationCreator();

          if (message.length <= MAX_MESSAGE_LENGTH && isValid) {
            router.replace(
              '/campaigns/create/[step]',
              `/campaigns/create/${newStep}`,
            );
          }
        } else {
          router.replace(
            '/campaigns/create/[step]',
            `/campaigns/create/${newStep}`,
          );
        }
      }
    },
    setConfirmationModalVisibility:
      campaignCreator.setConfirmationModalVisibility,
  };

  return {
    CampaignType,
    actions,
    state,
  };
};

export default useCreateCampaign;
