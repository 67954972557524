import styled from 'styled-components';
import { InlineStack, Spinner } from '@shopify/polaris';
import tokens from 'barn/tokens';
import AlertIcon from 'barn/icons/Alert';
import { fontSizes } from 'barn/tokens/typography';
import { ToastType } from 'src/store/models/save-toast';
import TickCircle from '../TickCircle';

const StyledSave = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${tokens.colors.greys[1]};
  border-radius: ${tokens.radii[3]};
  position: fixed;
  right: 0;
  bottom: 100px;
  left: 0;
  margin: 0 auto;
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[11]};
  width: max-content;
  font-size: ${fontSizes[3]};
  font-weight: 600;
  color: ${tokens.colors.greys[8]};
  z-index: ${tokens.zIndex[8]};
  transition: 0.2s all ease-in-out;

  &.hidden {
    bottom: -100px;
    opacity: 0;
  }

  p {
    margin-left: ${tokens.space.margin[6]};
  }
`;

type Props = {
  isLoading: boolean;
  hidden: boolean;
  children: any;
  type?: ToastType;
};

const Save = ({
  isLoading = false,
  hidden = true,
  children = 'Your changes have been saved',
  type = 'success',
}: Props) => {
  return (
    <StyledSave
      data-test={`${type}-toast`}
      className={`${hidden ? 'hidden' : ''}`}
    >
      <InlineStack gap='200'>
        {isLoading && <Spinner size='small' />}
        {!isLoading && type === 'success' ? <TickCircle done /> : <AlertIcon />}
        <div>{children}</div>
      </InlineStack>
    </StyledSave>
  );
};

export default Save;
