import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { UTMMedium } from 'src/modules/pricing/models';
import { useBrevoEmailEnabled } from './use-brevo-email-enabled';
import { useGlobalState } from './use-global-state';

export const useShowPricing = () => {
  const {
    state: {
      plans: { isFetching },
    },
    actions: { applyCoupon, showNewPricing },
  } = useGlobalState('pricing');

  const {
    state: {
      plans: { isFetching: isFetchingV2 },
    },
    actions: pricingV2Actions,
  } = useGlobalState('pricingV2');

  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const isNewPricingEnabled = useBrevoEmailEnabled();

  const router = useRouter();
  const path = router.asPath;
  const { isReady, query } = router;
  const { utm_medium, utm_source, coupon } = query as {
    utm_medium?: string;
    utm_source?: string;
    coupon?: string;
  };

  useEffect(() => {
    if (isAuthenticating) return;

    if (coupon && !isFetching) {
      if (isNewPricingEnabled) {
        pricingV2Actions.applyCoupon({ code: coupon });
      } else {
        applyCoupon(coupon);
      }
    }

    if (!path.includes('#pricing')) return;
    if (isReady) {
      if (isNewPricingEnabled) {
        pricingV2Actions.goToPricing({
          utmMedium: utm_medium as UTMMedium,
          utmSource: utm_source,
        });
      } else {
        showNewPricing({
          utmMedium: utm_medium as UTMMedium,
          utmSource: utm_source,
        });
      }
    }
  }, [
    path,
    isReady,
    utm_medium,
    utm_source,
    coupon,
    isAuthenticating,
    isFetching,
    isFetchingV2,
    isNewPricingEnabled,
  ]);
};
