import { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState, Dispatch } from 'src/store';

const useCreateNotification = () => {
  const state = useSelector(
    (storeState: StoreState) => storeState.notificationCreator,
  );
  const campaignCreatorState = useSelector(
    (storeState: StoreState) => storeState.campaignCreator,
  );
  const dispatch: Dispatch = useDispatch();
  const { notificationCreator, campaignCreator } = dispatch;
  const router = useRouter();

  const debouncedCreateCampaign = useMemo(
    () => debounce(campaignCreator.createCampaign, 1000),
    [],
  );

  const saveAsDraft = () => {
    if (
      ['/campaigns/create/1', '/campaigns/create/2'].includes(router.asPath) &&
      campaignCreatorState.isDraft
    ) {
      debouncedCreateCampaign({ isDraft: true });
    }
  };

  const getPropSetter =
    (prop: string) => (value: string | boolean | number) => {
      notificationCreator.setProp({ value, prop });
      saveAsDraft();
    };

  const setDiscountCode = getPropSetter('discountCode');
  const debouncedValidateDiscountCode = useMemo(
    () => debounce(campaignCreator.validateDiscountCode, 1000),
    [],
  );

  const handleDiscountCode = value => {
    setDiscountCode(value);
    if (value) {
      debouncedValidateDiscountCode(value);
    } else {
      campaignCreator.clearDiscountCodeValidation();
    }
  };

  const actions = useMemo(
    () => ({
      ...notificationCreator,
      setCampaignTemplates: payload =>
        notificationCreator.setCampaignTemplates(payload),
      shut: () => notificationCreator.shut(),
      loadState: (newState: AnyObject) =>
        notificationCreator.loadState(newState),
      setTitle: getPropSetter('title'),
      setMessage: getPropSetter('message'),
      setIcon: getPropSetter('icon'),
      setPrimaryLink: getPropSetter('primaryLink'),
      handleDiscountCode,

      addButton: notificationCreator.addButton,
      updateButton: (button: any) => {
        notificationCreator.updateButton(button);
        saveAsDraft();
      },
      removeButton: (index: number) => {
        notificationCreator.removeButton(index);
        saveAsDraft();
      },
      setDesktopHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          desktop: url,
        });
        saveAsDraft();
      },
      setMobileHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          mobile: url,
        });

        saveAsDraft();
      },
      setMacHeroImage: (url: string) => {
        notificationCreator.setHeroImage({
          macos: url,
        });
        saveAsDraft();
      },
    }),
    [getPropSetter],
  );

  return {
    actions,
    state,
  };
};

export default useCreateNotification;
